<template>
    <form method="post" @submit.prevent="submit">
        <slot :data="form"
              :hasErrors="hasErrors"
              :getError="getError"
              :successMessage="success"
              :errors="errors"
              :loading="loading"
        ></slot>
    </form>
</template>

<script>
export default {
    name: 'JsonForm',

    props: {
        action: {
            type: String,
        },
        values: {
            type: Object,
            default: () => {
                return {}
            },
        },
    },

    data () {
        return {
            form: this.values,
            success: false,
            errors: false,
            loading: false,
        }
    },

    methods: {
        hasErrors (fieldName) {
            return this.errors && this.errors[ fieldName ]
        },

        getError (fieldName) {
            return this.errors[ fieldName ][ 0 ]
        },

        submit () {
            this.loading = true
            this.errors = false

            this.addJob(this.action)

            this.$api.post(this.action, this.form).then(({ data }) => {
                this.success = data.message || 'Success.'
                this.$emit('success', data)
            }).catch(data => {
                this.errors = data
                this.$emit('error', data)
            }).finally(() => {
                this.loading = false
                this.finishJob(this.action)
            })
        },
    },
}
</script>
