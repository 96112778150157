<template>
    <div class="c-login-view">
        <form class="login-form" data-test="loginForm" is="json-form" :action="action" @success="loginUser" @error="onError">
            <template slot-scope="form">
                <mercur-card>
                    <h2 class="font-weight-normal">Login</h2>

                    <mercur-input v-model="form.data.username" autofocus>
                        E-mail / username
                    </mercur-input>

                    <mercur-input v-model="form.data.password" type="password">
                        Password
                    </mercur-input>

                    <p v-if="form.successMessage" v-text="form.successMessage"></p>
                    <p v-if="errorMessage" data-test="error" class="error" v-text="errorMessage"></p>
                    <div class="align-right">
                        <a data-test="passwordReset" @click.prevent="isDialogOpen = true">Forgot password</a>
                    </div>

                    <div class="text-right">
                        <mercur-button class="btn btn-raisd btn-yellow text-uppercase mt-2" type="submit" :disabled="form.loading">Log in</mercur-button>
                    </div>
                </mercur-card>
            </template>
        </form>
        <div>
            <mercur-dialog :is-open.sync="isDialogOpen">
                <div slot="default">
                    <form data-test="passwordResetForm" @submit.prevent="requestNewPassword">
                        <mercur-input v-model="username" id="usernamePasswordRecovery" type="email" required>
                            Type your username/email
                        </mercur-input>

                        <div slot="footer">
                            <mercur-button class="btn btn-yellow text-uppercase" :disabled="isResettingPassword" @click="isDialogOpen = false">Close</mercur-button>
                            <mercur-button type="submit" class="btn btn-raised btn-yellow text-uppercase" :disabled="isResettingPassword">Request new password</mercur-button>
                        </div>
                    </form>
                </div>
            </mercur-dialog>
        </div>
    </div>
</template>

<script>
import JsonForm from '@/components/utils/JsonForm'
import { mapActions } from 'vuex'
import CONFIG from '@root/config'

export default {
    name: 'LoginView',
    components: { JsonForm },
    computed: {
        action () {
            return CONFIG.API.ROUTES.LOGIN
        },
    },

    data () {
        return {
            errorMessage: '',
            username: '',
            isDialogOpen: false,
            isResettingPassword: false,
        }
    },

    methods: {
        ...mapActions('auth', [ 'login', 'recoverPassword', 'setAllowedActions' ]),
        loginUser (data) {
            if (data.allowedActions) {
                this.setAllowedActions(data.allowedActions)
            }
            this.login(data.jwt).then(path => {
                this.$router.push(path || '/')
            })
        },
        onError (data) {
            console.error(data)

            if (CONFIG.DEBUG) {
                this.errorMessage = data

                return
            }

            this.errorMessage = 'Something went wrong while trying to login. Please try again.'
        },
        requestNewPassword () {
            this.isResettingPassword = true

            this.recoverPassword({ username: this.username }).then(() => {
                this.$root.$emit('notification:global', {
                    message: 'Password reset email was sent',
                })
            }).finally(() => {
                this.isResettingPassword = false
                this.isDialogOpen = false
                this.username = ''
            })
        },
    },
}
</script>

<style scoped>
    .c-login-view {
        margin: auto;
        width: 100%;
    }

    .login-form {
        max-width: 400px;
        margin: auto;
        width: 100%;
    }
</style>
